import {
  ExploreChannel,
  ExploreChartConfig,
  ExploreField,
  ExploreVisualizationType,
  aggregationChannels,
  getFieldActiveScaleType,
  isHistogram,
  isPie,
} from "@hex/common";

import type { ExploreFieldMenuField } from "./ExploreFieldMenuFieldRenderer.js";

export function getConfigurableFields(
  field: ExploreField,
  vizType: ExploreVisualizationType,
  chartConfig: ExploreChartConfig,
  hasCustomTimeUnits: boolean,
): ExploreFieldMenuField[] {
  const configFields: ExploreFieldMenuField[] = [];
  const activeScaleType = getFieldActiveScaleType(field);

  const showCustomTimeUnits =
    ["DATE", "TIMESTAMPTZ", "TIMESTAMP"].includes(field.dataType) &&
    hasCustomTimeUnits;

  if (vizType === "pivot-table") {
    if (
      field.fieldType !== "MEASURE" &&
      aggregationChannels.has(field.channel)
    ) {
      configFields.push("aggregation");
    }
    if (
      truncationChannels.has(field.channel) &&
      (activeScaleType === "datetime" || showCustomTimeUnits)
    ) {
      configFields.push("truncUnit");
    }
    if (
      displayFormatChannels.has(field.channel) &&
      activeScaleType !== "string"
    ) {
      configFields.push("displayFormat");
    }
  } else {
    if (
      field.fieldType !== "MEASURE" &&
      aggregationChannels.has(field.channel) &&
      !(isPie(chartConfig) && field.channel === "color")
    ) {
      configFields.push("aggregation");
    }

    if (
      truncationChannels.has(field.channel) &&
      ((activeScaleType === "datetime" &&
        !(
          isPie(chartConfig) && ["color", "cross-axis"].includes(field.channel)
        )) ||
        showCustomTimeUnits) &&
      !(isHistogram(chartConfig) && field.channel === "base-axis")
    ) {
      configFields.push("truncUnit");
    }
    if (
      displayFormatChannels.has(field.channel) &&
      activeScaleType !== "string"
    ) {
      configFields.push("displayFormat");
    }
    if (
      sortChannels.has(field.channel) &&
      activeScaleType === "string" &&
      field.fieldType !== "MEASURE" &&
      field.aggregation == null
    ) {
      configFields.push("sort");
    }
    if (
      scaleTypeChannels.has(field.channel) &&
      field.fieldType !== "MEASURE" &&
      field.aggregation == null &&
      !(
        isPie(chartConfig) && ["color", "cross-axis"].includes(field.channel)
      ) &&
      !(isHistogram(chartConfig) && field.channel === "base-axis")
    ) {
      configFields.push("scaleType");
    }
    //TODO(EXPLORE) binning for facets and histogram base
  }

  if (field.fieldType !== "MEASURE") {
    if (configFields.length > 0) {
      configFields.push("menuDivider");
    }
    configFields.push("filter");
  }

  return configFields;
}

export const truncationChannels: Set<ExploreChannel | undefined> = new Set([
  "row",
  "column",
  "base-axis",
  "h-facet",
  "v-facet",
  // "color", // add later
  // "tooltip" // add later
]);

const scaleTypeChannels: Set<ExploreChannel | undefined> = new Set([
  "base-axis",
  "cross-axis",
  "color",
]);

const sortChannels: Set<ExploreChannel | undefined> = new Set([
  "base-axis",
  "h-facet",
  "v-facet",
  "color",
]);

const displayFormatChannels: Set<ExploreChannel | undefined> = new Set([
  "value",
  "row",
  "column",
  "base-axis",
  "cross-axis",
  "color",
]);
